<template>
  <div class="content">
    <el-form inline :model="search" class="tb-form">
      <el-form-item label="用户姓名：">
        <el-input v-model="search.name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="手机号码：">
        <el-input v-model="search.mobile" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="注册日期：">
        <el-date-picker
          v-model="search.register"
          type="daterange"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          :clearable="false"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <!-- <el-form-item label="地区：">
        <div class="flex tb-select-group">
          <el-select v-model="value" placeholder="国家">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
            <el-select v-model="value" placeholder="省份">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
            <el-select v-model="value" placeholder="城市">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="用户性别：">
        <el-select v-model="value">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item> -->
      <!-- <el-form-item label="会员等级：">
        <el-select v-model="search.vip">
          <el-option
            v-for="item in vip"
            :key="item.id"
            :label="item.vipName"
            :value="item.id"
          />
        </el-select>
      </el-form-item> -->
      <el-form-item label="上次交易：">
        <el-date-picker
          v-model="search.order"
          type="daterange"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <!-- <el-form-item label="微信昵称：">
        <el-select v-model="search.nickname">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="交易总额：">
        <div class="flex">
          <el-input v-model="search.total_start_price" style="width: 100px;" placeholder="请输入"></el-input>
          <span style="margin: 0 8px;">--</span>
          <el-input v-model="search.total_end_price" style="width: 100px;" placeholder="请输入"></el-input>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch">搜索</el-button>
      </el-form-item>
    </el-form>
    <div class="tablebox">
      <el-table
        :data="tableData"
        class="tb-table"
        @row-dblclick="toDetail"
      >
        <el-table-column prop="id" label="ID编号"/>
        <el-table-column prop="nick_name" label="微信昵称"/>
        <el-table-column prop="phone" label="手机号码"/>
        <el-table-column prop="vipname" label="会员等级"/>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <router-link :to="`/member/detail?id=${scope.row.id}`" class="edit">查看详情</router-link>
            <el-divider direction="vertical"></el-divider>
            <span class="edit" @click="sendCoupon(scope.row)">发送优惠券</span>
            <!-- <el-divider direction="vertical"></el-divider>
            <span class="edit" @click="sendSMS(scope.row.id)">发送短信</span> -->
            <!-- <el-divider direction="vertical"></el-divider>
            <span class="edit" @click="setVip(scope.row)">设置vip等级</span>
            <el-divider direction="vertical"></el-divider>
            <span class="edit" @click="addIntegral(scope.row)">手动增加积分</span> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        class="tb-pagination"
        layout="prev, pager, next"
        :current-page.sync="page.no + 1"
        :page-size="page.size"
        :total="page.total"
        @current-change="pageChange"
      />
    </div>
    <el-dialog
      title="设置vip等级"
      :visible.sync="vipVisible"
      width="400px"
    >
      <div>
        <span>vip等级：</span>
        <el-select v-model="viplevel">
          <el-option
            v-for="item in vip"
            :key="item.id"
            :label="item.vipName"
            :value="item.id"
          />
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="vipVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmVip">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="发放优惠券"
      width="50vw"
      :visible.sync="couponVisible"
    >
      <div style="display: flex; align-items: center; margin-bottom: 24px;">
        <span>搜索优惠券：</span>
        <el-input v-model="couponname" style="width: 216px;"></el-input>
      </div>
      <el-table
        ref="multipleTable"
        :data="couponList"
        class="tb-table"
        height="48vh"
        @selection-change="selectionChange"
        @row-click="selectionSingleChange"
      >
        <el-table-column type="selection" width="80" />
        <el-table-column prop="couponName" label="优惠券名称" />
      </el-table>
      <el-pagination
        background
        class="tb-pagination"
        layout="prev, pager, next"
        :current-page.sync="coupon.no + 1"
        :page-size="coupon.size"
        :total="coupon.total"
        @current-change="couponChange"
      />
      <span slot="footer">
        <el-button @click="couponVisible = false">取 消</el-button>
        <el-button type="primary" @click="couponConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="手动添加积分"
      width="50vw"
      :visible.sync="integralVisible">
      <el-input placeholder="请输入添加积分" v-model="integral"></el-input>
      <span slot="footer">
        <el-button @click="integralVisible = false">取 消</el-button>
        <el-button type="primary" @click="integralConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
  name: 'MemberList',
  data() {
    return {
      search: {
        name: '',
        mobile: '',
        vip_level: '',
        total_start_price: '',
        total_end_price: '',
        register: [],
        order: null
      },
      tableData: [],
      page: {
        no: 0,
        size: 10,
        total: 0
      },
      choose: null,
      viplevel: '',
      vipVisible: false,
      couponChoose: [],
      coupon: {
        no: 0,
        size: 10,
        total: 0
      },
      couponname: '',
      couponList: [],
      couponVisible: false,
      integralVisible: false,
      integral: ''
    }
  },
  computed: mapState([
    'vip'
  ]),
  mounted() {
    let path = this.$route.path
    if (path.indexOf('/') === 0) path = path.substring(1)
    const currentPage = {
      link: path,
      name: '会员列表'
    }
    this.$store.commit('changeRoute', currentPage)
    this.getList()
  },
  methods: {
    async getList() {
      let {no, size} = this.page
      const params = {
        name: this.search.name,
        mobile: this.search.mobile,
        vip_level: this.search.vip_level,
        total_start_price: this.search.total_start_price,
        total_end_price: this.search.total_end_price,
        start_time: this.search.register[0],
        end_time: this.search.register[1],
        page_size: size,
        page_no: no
      }
      if (this.search.order) {
        params.order_start_time = this.search.order[0]
        params.order_end_time = this.search.order[1]
      }
      const url = 'admin/customer/list'
      const data = await this.$https.get(url, {params})
      if (!data) return
      data.list.forEach(item => {
        const vipitem = this.vip.find(v => v.id === item.vip_level)
        item.vipname = vipitem ? vipitem.vipName : ''
      })
      this.page.total = data.total_count
      this.tableData = data.list
    },
    async getCouponList() {
      const {no, size} = this.coupon
      const params = {
        page_size: size,
        page_no: no,
        name: this.couponname,
        status: 1
      }
      const url = 'admin/coupon/list'
      const data = await this.$https.get(url, {params})
      if (!data) return
      this.coupon.total = data.total_count
      this.couponList = data.list
    },
    onSearch() {
      this.page.no = 0
      this.getList()
    },
    pageChange(no) {
      this.page.no = no - 1
      this.getList()
    },
    sendCoupon(item) {
      this.choose = item
      this.couponVisible = true
      this.getCouponList()
    },
    selectionChange(choose) {
      if (choose.length > 1) {
        const ele = choose.pop()
        this.$refs.multipleTable.clearSelection()
        this.$refs.multipleTable.toggleRowSelection(ele)
        this.couponChoose = ele
      } else {
        this.couponChoose = choose[0]
      }
    },
    selectionSingleChange(choose) {
      this.couponChoose = choose
      this.$refs.multipleTable.clearSelection()
      this.$refs.multipleTable.toggleRowSelection(choose)
    },
    couponChange(no) {
      this.coupon.no = no - 1
      this.getCouponList()
    },
    couponConfirm() {
      if (!this.couponChoose.id) {
        this.$message.error('请选择优惠券')
        return
      }
      const params = {
        send_id: this.choose.id,
        coupon_id: this.couponChoose.id
      }
      const url = 'admin/customer/send/coupon'
      this.$https.post(url, params).then(res => {
        if (res) {
          this.$message.success('发放成功')
          this.couponname = ''
          this.choose = null
          this.couponList = []
          this.couponVisible = false
        }
      })
    },
    // sendSMS(id) {
    //   console.log(id)
    // },
    setVip(item) {
      this.viplevel = item.vip_level || ''
      this.choose = item
      this.vipVisible = true
    },
    addIntegral(item) {
      this.choose = item
      this.integral = ''
      this.integralVisible = true
    },
    integralConfirm() {
      if (!this.integral) {
        this.$message.error('请输入积分')
        return
      }
      const params = {send_id:this.choose.id, integral: this.integral}
      const url = 'admin/customer/add/integral'
      this.$https.post(url, params).then(res => {
        if (res) {
          this.$message.success('设置成功')
          this.integral = ''
          this.choose = null
          this.integralVisible = false
          this.getList()
        }
      })
    },
    confirmVip() {
      if (!this.viplevel) {
        this.$message.error('请设置vip等级')
        return
      }
      const params = {
        send_id: this.choose.id,
        vip_level: this.viplevel
      }
      const url = 'admin/customer/vip/set'
      this.$https.post(url, params).then(res => {
        if (res) {
          this.$message.success('设置成功')
          this.viplevel = ''
          this.choose = null
          this.vipVisible = false
          this.getList()
        }
      })
    },
    toDetail({id}) {
      this.$router.push('/member/detail?id=' + id)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
